body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Questrial','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  overflow-y: scroll;
  scrollbar-width: none;  /* Para Firefox */
  -ms-overflow-style: none;  /* Para IE y Edge */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Questrial','Courier New',
    monospace;
}

@font-face {
  font-family: 'Questrial';
  src: url('/public/fonts/Questrial-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FiraSans';
  src: url('/public/fonts/FiraSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Ocultar scrollbar en navegadores basados en WebKit */
body::-webkit-scrollbar {
display: none;
}